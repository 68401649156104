import { createAction, props } from '@ngrx/store';
import { ListShowroom, LogAudit } from '@core/models/responsehttp.model';
import {
  AsociadoObject,
  BuyerObject,
  CarObject,
  ContratoDetail,
  ContratoObject,
  SellerObject,
  VentaObject,
} from '@contratos/models/contrato-mc.model';

export const loadShowroomInit = createAction('[Showroom API-Init] Load All Success', props<{ abreviatura: string }>());

export const loadShowroom = createAction('[Showroom API] Load All Success', props<{ abreviatura: string }>());

export const loadSuccessShowroom = createAction(
  '[Showroom API] Loaded All Success',
  props<{ showrooms: ListShowroom[] }>()
);

export const loadInitDetailContrato = createAction('[Contrato API] Load init', props<{ id: string }>());

export const loadSuccessDetailContrato = createAction(
  '[Contrato API] Loaded All Success',
  props<{ contrato: ContratoDetail }>()
);

export const loadErrorDetailContrato = createAction('[Contrato API] Loaded error', props<{ message: string }>());

export const savedSaleInit = createAction('[Save] Saved SALE init', props<{ sale: VentaObject }>());
export const savedSaleSuccess = createAction(
  '[Saved] Saved SALE Success',
  props<{ sale: VentaObject; message: string }>()
);
export const savedSaleError = createAction('[Not Saved] Saved SALE Error', props<{ errorMessage: string }>());

export const savedCarInit = createAction('[Save] Saved CAR init', props<{ car: CarObject }>());
export const savedCarSuccess = createAction('[Saved] Saved CAR Success', props<{ car: CarObject; message: string }>());
export const savedCarError = createAction('[Not Saved] Saved CAR Error', props<{ errorMessage: string }>());

export const savedUserInit = createAction(
  '[Save] Saved USER init',
  props<{ user: BuyerObject | SellerObject; typeUser: string }>()
);
export const savedUserSuccessBuyer = createAction(
  '[Saved] Saved USER-BUYER Success',
  props<{ buyer: BuyerObject; message: string }>()
);
export const savedUserSuccessSeller = createAction(
  '[Saved] Saved USER-SELLER Success',
  props<{ seller: SellerObject; message: string }>()
);
export const savedUserError = createAction('[Not Saved] Saved USER Error', props<{ errorMessage: string }>());

export const savedChangesSale = createAction('[Saved SALE] Saved Changes', props<{ savedSale: boolean }>());
export const savedChangesCar = createAction('[Saved CAR] Saved Changes', props<{ savedCar: boolean }>());
export const savedChangesBuyer = createAction('[Saved BUYER] Saved Changes', props<{ savedBuyer: boolean }>());
export const savedChangesSeller = createAction('[Saved SELLER] Saved Changes', props<{ savedSeller: boolean }>());

export const setContratoSeller = createAction('[Update Seller] update store', props<{ seller: SellerObject }>());
export const setContratoBuyer = createAction('[Update Buyer] update store', props<{ buyer: BuyerObject }>());
//export const setContratoCar = createAction('[Update Car] update store', props<{ car: CarObject }>());
//export const setContratoSale = createAction('[Update Sale] update store', props<{ sale: VentaObject }>());

export const updateContratoPDFFinallyInit = createAction(
  '[Load Update Status] Load',
  props<{ contrato: Partial<ContratoObject> }>()
);
export const updateContratoPDFFinallySuccess = createAction(
  '[Loaded Update Status] Loaded Success',
  props<{ message: string }>()
);
export const updateContratoPDFFinallyError = createAction(
  '[Loaded Update Status] Loaded Error',
  props<{ errorMessage: string }>()
);

export const loadInitAsociate = createAction('[Asociate API] Load init');
export const loadSuccessAsociate = createAction(
  '[Asociate API] Loaded All Success',
  props<{ asociate: AsociadoObject[] }>()
);

//Acciones para guardar el log
export const initLogSaved = createAction('[LOG-AUDIT API] Load init', props<{ log: LogAudit }>());
export const loadedLogSavedSucces = createAction('[LOG-AUDIT API] Load success');

export const loadErrorLogSaved = createAction('[LOG-AUDIT API] Loaded error', props<{ errorMessage: string }>());

export const resetStore = createAction('[RESET] STORE');
